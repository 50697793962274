<template>
<div class="view-home">
  <div class="container">
    <div class="crossword">
      <div v-for="(row, rowIndex) in crossword" :key="rowIndex" class="crossword-row">
        <div v-for="(cell, colIndex) in row" :key="colIndex" :class="['crossword-cell', { 'black-cell': cell.black }]">
          <template v-if="!cell.black">
            <input v-model="cell.value" :maxlength="2" @input="checkAnswer(rowIndex, colIndex)" :placeholder="cell.placeholder" :disabled="cell.disabled" />
          </template>
        </div>
      </div>
    </div>
<!--    <button @click="showAnswers">-->
<!--      Ответы-->
<!--    </button>-->
    <div>
      Поставьте нужное число в данную последовательность
    </div>
    <div>
      3, 6, 18, 75, 4, 8, 24, <input style="width: 40px; outline: none"/>, 5, 10, 30,<input style="width: 40px; outline: none"/>
    </div>
    <div>
      16. Замените знаки вопроса правильными цифрами, чтобы можно было вскрыть сейф.
    </div>
    <div style="position: relative">
      <img src="/images/safe.png">
      <div class="crossword-safe">
        <div v-for="(row, rowIndex) in crosswordSafe" :key="rowIndex" class="crossword-row">
          <div v-for="(cell, colIndex) in row" :key="colIndex" :class="['crossword-cell-safe', { 'black-cell': cell.black }]">
            <template v-if="!cell.black">
              <input v-model="cell.value" :maxlength="2" @input="checkAnswer(rowIndex, colIndex)" :placeholder="cell.placeholder" :disabled="cell.disabled" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <img src="/images/flower.png" width="800">
      <input class="flower_letter" placeholder="?"/>
      <input class="flower_word" placeholder="?"/>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      crossword: [
        [{ value: '', black: false, placeholder: '?', disabled: false }, { value: 'x', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '=', black: false, placeholder: '', disabled: true }, { value: '21', black: false, placeholder: '', disabled: true }],
        [{ value: 'x', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: 'x', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: 'x', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }],
        [{ value: '', black: false, placeholder: '?', disabled: false }, { value: 'x', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '=', black: false, placeholder: '', disabled: true }, { value: '8', black: false, placeholder: '', disabled: true }],
        [{ value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }],
        [{ value: '', black: false, placeholder: '?', disabled: false }, { value: 'x', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '=', black: false, placeholder: '', disabled: true }, { value: '26', black: false, placeholder: '', disabled: true }],
        [{ value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '-', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }],
        [{ value: '9', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '16', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '11', black: false, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }, { value: '', black: true, placeholder: '', disabled: true }]
      ],
      crosswordSafe: [
        [{ value: '9', black: false, placeholder: '', disabled: true }, { value: '8', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '7', black: false, placeholder: '', disabled: true }, { value: '8', black: false, placeholder: '', disabled: true }, { value: '4', black: false, placeholder: '', disabled: true }, { value: '5', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '6', black: false, placeholder: '', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }],
        [{ value: '', black: false, placeholder: '?', disabled: false }, { value: '5', black: false, placeholder: '', disabled: true }, { value: '0', black: false, placeholder: '?', disabled: true }, { value: '4', black: false, placeholder: '', disabled: true }, { value: '5', black: false, placeholder: '', disabled: true }, { value: '1', black: false, placeholder: '', disabled: true }, { value: '2', black: false, placeholder: '', disabled: true }, { value: '0', black: false, placeholder: '?', disabled: true }, { value: '', black: false, placeholder: '?', disabled: false }, { value: '4', black: false, placeholder: '?', disabled: true }],
      ]
    };
  },
  methods: {
    checkAnswer(rowIndex, colIndex) {
      const cellValue = this.crossword[rowIndex][colIndex].value;
      if (!isNaN(cellValue)) {
        console.log('это число:', cellValue);
      } else {
        console.log('это не число:', cellValue);
      }
    },
    showAnswers() {
      console.log(this.crossword[0][0].value, this.crossword[0][2].value, this.crossword[0][4].value, this.crossword[2][0].value, this.crossword[2][2].value, this.crossword[2][4].value, this.crossword[4][0].value, this.crossword[4][2].value, this.crossword[4][4].value)
    }
  }
}
</script>

<style>
.crossword {
  display: flex;
  flex-direction: column;
}

.crossword-safe {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 183px;
  left: 266px;
  background: white;
}

.crossword-row {
  display: flex;
}

.crossword-cell {
  border: 1px solid #ccc;
  width: 60px;
  height: 60px;
}

.crossword-cell-safe {
  border: 1px solid #ccc;
  width: 30px;
  height: 56px;
}

.black-cell {
  background-color: black;
}

.crossword-cell input {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
}

.crossword-cell-safe input {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
}

.flower_letter {
  outline: none;
  position: absolute;
  top: 270px;
  left: 165px;
  width: 40px;
  font-size: 40px;
}

.flower_word {
  outline: none;
  position: absolute;
  top: 270px;
  left: 300px;
  width: 180px;
  font-size: 40px;
}

</style>